/**
 * @generated SignedSource<<12227406bf5ad3a06cb3a7dc03ccf626>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginActivateInput = {
  clientMutationId?: string | null;
  login: LoginActivate;
};
export type LoginActivate = {
  activationKey: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};
export type EmailLoginActivateMutation$variables = {
  input: LoginActivateInput;
};
export type EmailLoginActivateMutation$data = {
  readonly loginActivate: {
    readonly errors: ReadonlyArray<{
      readonly messages: ReadonlyArray<string>;
      readonly path: string;
    }>;
  };
};
export type EmailLoginActivateMutation = {
  response: EmailLoginActivateMutation$data;
  variables: EmailLoginActivateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messages",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailLoginActivateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LoginActivatePayload",
        "kind": "LinkedField",
        "name": "loginActivate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailLoginActivateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LoginActivatePayload",
        "kind": "LinkedField",
        "name": "loginActivate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "024af793f5a5d6dd9014bf74a0ddf643",
    "id": null,
    "metadata": {},
    "name": "EmailLoginActivateMutation",
    "operationKind": "mutation",
    "text": "mutation EmailLoginActivateMutation(\n  $input: LoginActivateInput!\n) {\n  loginActivate(input: $input) {\n    errors {\n      path\n      messages\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "03e2bd65bf0e61a0be5fcb44560d9f7f";

export default node;
